import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Context } from "../context"
import shapeProjectState from "../lib/shape-projects-state"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import Meta from "./meta"
import * as fonts from "../fonts"

const theme = {
  black: "#000000",
  white: "#ffffff",
  grey: "#ebebeb",

  // media queries
  desktoplarge: "1800px",
  desktop: "1280px",
  tablet: "1021px",
  mobile: "750px",
  mobilesmall: "325px",

  // easing
  easing: "transform 0.6s cubic-bezier(0.86, 0, 0.07, 1)",

  // z-index
}

const GlobalStyle = createGlobalStyle`
    /* @font-face {
        font-family: 'Swiss721';
        font-weight: 600;
        font-style: normal;
        src: url('${fonts.swissMedium}') format("woff");
        font-display: fallback;
    }

    @font-face {
        font-family: 'Swiss721Italic';
        font-weight: 600;
        font-style: normal;
        src: url('${fonts.swissItalic}') format("woff");
    } */

    * {
        box-sizing: border-box;
        margin: 0;
    }

    html,
    body,
    #___gatsby,
    #gatsby-focus-wrapper,
    main {
      @media (max-width: ${theme.mobile}) {
          height: 100%;
          overflow: hidden;
        }
    }

    html {
        box-sizing: border-box;
        margin: 0;
        font-size: 10px;
        color: ${theme.black};
    }

    body {
        padding: 0;
        margin: 0;
        font-size: 2.4rem;
        line-height: 1;
        overflow-x: hidden;
        font-family: 'Swiss721', Helvetica, sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media (min-width: ${theme.tablet}) {
          overflow: hidden;
        }

        @media (max-width: ${theme.desktop}) {
          font-size: 2.2rem;
        }

        @media (max-width: ${theme.mobile}) {
          font-size: 1.8rem;
        }

        @media (max-width: ${theme.mobilesmall}) {
          font-size: 1.6rem;
        }
    }

    a {
        text-decoration: none;
        color: ${theme.black};
    }

    a:hover {
        font-family: 'Swiss721Italic', Helvetica, sans-serif;
    }

    ul {
      list-style-type: none;
    }

    ul,
    li,
    h1,
    p,
    span {
      margin: 0;
      padding: 0;
      font-size: 2.4rem;
      line-height: 1;

        @media (max-width: ${theme.desktop}) {
          font-size: 2.2rem;
        }

        @media (max-width: ${theme.mobile}) {
          font-size: 1.8rem;
        }

        @media (max-width: ${theme.mobilesmall}) {
          font-size: 1.6rem;
        }
    }
`

class Layout extends Component {
  static contextType = Context

  componentDidMount() {
    const projects = shapeProjectState(this.props.projects)
    this.context.setProjectsState(projects, projects.length - 1)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Meta />
        <GlobalStyle />
        <main>{this.props.children}</main>
      </ThemeProvider>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allContentfulProjects {
          nodes {
            projects {
              title
              client
              images {
                image {
                  fluid {
                    ...GatsbyContentfulFluid_withWebp
                  }
                  file {
                    details {
                      size
                      image {
                        width
                        height
                      }
                    }
                  }
                  localFile {
                    absolutePath
                    relativePath
                    publicURL
                    sharp: childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                layout
                backgroundColor
                fontColor
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout
        data={data}
        projects={data.allContentfulProjects.nodes[0].projects}
        {...props}
      />
    )}
  />
)
