import React, { useContext } from "react"
import styled, { css } from "styled-components"
import Image from "gatsby-image"
import { Context } from "../context"

const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: ${({ theme }) => theme.white};
  background: ${({ backgroundColor }) => backgroundColor};

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  .gatsby-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const StyledImage = styled(Image)`
  ${({ layout }) =>
    layout &&
    css`
      @media (min-width: ${({ theme }) => theme.mobile}) {
        width: 50%;
        height: auto;
      }
    `}
  ${({ layout }) =>
    !layout &&
    css`
      @media (min-width: ${({ theme }) => theme.mobile}) {
        height: 100%;
        width: 100%;

        > img,
        > picture img {
          height: 60vh !important;
          width: auto !important;
          top: 50% !important;
          left: 50% !important;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    `}

  ${({ landscape }) =>
    landscape
      ? css`
          @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
            height: auto;
          }
        `
      : css`
          @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 60%;
            height: auto;
          }
        `}
`

const Project = ({ project, active, first }) => {
  const {
    device: { mobile },
    cycling,
  } = useContext(Context)
  return (
    <Container
      backgroundColor={project.backgroundColor}
      active={mobile ? active : cycling ? active : false}
    >
      <StyledImage
        alt={project.title}
        fluid={project.image.localFile.sharp.fluid}
        layout={project.layout === "Layout 1 - 50% Width"}
        landscape={project.landscape}
      />
    </Container>
  )
}

export default Project
