import { useStaticQuery, graphql } from "gatsby"

const useHeaderContent = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulHeader {
        nodes {
          title
          projectTrigger
          main {
            main
          }
          contact {
            contact
          }
        }
      }
    }
  `)

  return {
    ...data.allContentfulHeader.nodes[0],
    trigger: data.allContentfulHeader.nodes[0].projectTrigger,
    main: data.allContentfulHeader.nodes[0].main.main,
    contact: data.allContentfulHeader.nodes[0].contact.contact,
  }
}

export default useHeaderContent
