import React from "react"
import Helmet from "react-helmet"
import useSiteMetadata from "../hooks/use-sitemetadata"

const Meta = () => {
  const { title, description } = useSiteMetadata()

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <title>{title}</title>
    </Helmet>
  )
}

export default Meta
