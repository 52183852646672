import React, { useState, useContext } from "react"
import useHeaderContent from "../hooks/use-headercontent"
import styled, { css } from "styled-components"
import marked from "marked"
import { Context } from "../context"
import ProjectTitle from "./title"
import setTargetBlank from "../lib/set-target-blank"
import Arrow from "./arrow"

const StyledHeader = styled.header`
  padding: 1rem;

  @media (min-width: ${({ theme }) => theme.mobile}) {
    position: absolute;
    z-index: 10;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    transform: translateY(0);
    transition: ${({ theme }) => theme.easing};
  }

  ${({ pushUp }) =>
    pushUp &&
    css`
      @media (max-width: ${({ theme }) => theme.mobile}) {
        transform: translateY(-100%);
      }
    `}

  > div {
    display: flex;
  }

  .trigger {
    cursor: pointer;
  }

  .plus {
    @media (max-width: ${({ theme }) => theme.mobilesmall}) {
      padding: 0.15rem 0;
      font-size: 1.8rem;
    }
  }

  .trigger {
    padding-left: 0.5rem;

    &:hover {
      font-family: "Swiss721Italic", Helvetica, sans-serif;
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
      display: none;
    }
  }

  ${({ cycling }) =>
    cycling &&
    css`
      color: ${({ fontColor }) => fontColor};

      a,
      li,
      p {
        color: ${({ fontColor }) => fontColor};
      }
    `}
`

const Contact = styled.div`
  ${({ open }) =>
    open
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`

const Header = () => {
  const [open] = useState(true)
  const {
    cycleActive,
    killCycle,
    projects,
    active,
    cycling,
    mobileProjectsActive,
    device: { mobile },
  } = useContext(Context)
  const { title, trigger, main, contact } = useHeaderContent()
  return (
    <StyledHeader
      fontColor={projects.length > 0 ? projects[active].fontColor : "#ffffff"}
      cycling={cycling}
      pushUp={mobileProjectsActive}
    >
      <div>
        <h1>{title}</h1>{" "}
        <span
          className="trigger"
          onMouseEnter={() => cycleActive()}
          onMouseLeave={() => killCycle()}
        >
          ({trigger})
        </span>
      </div>

      <ProjectTitle visible={cycling} hide={"mobile"} />

      <div dangerouslySetInnerHTML={{ __html: setTargetBlank(marked(main)) }} />

      <div className="plus">{!open ? "+" : "−"}</div>

      <Contact
        open={open}
        dangerouslySetInnerHTML={{ __html: setTargetBlank(marked(contact)) }}
      />

      {mobile && <Arrow />}
    </StyledHeader>
  )
}

export default Header
