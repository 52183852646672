import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Projetcs from "../components/projects"
import styled from "styled-components"

const Inner = styled.div`
  @media (min-width: ${({ theme }) => theme.mobile}) {
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 100%;
    position: relative;

    > * {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
    }
  }
`

const Home = () => (
  <Layout>
    <Inner>
      <Header />
      <Projetcs />
    </Inner>
  </Layout>
)

export default Home
