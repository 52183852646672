export default projects => {
  const flattended = []

  projects.map((project, n) =>
    project.images.map((image, i) =>
      flattended.push({
        ...image,
        i:
          n < 9
            ? "0" + (n + 1).toString() + (i + 1).toString()
            : (n + 1).toString() + (i + 1).toString(),
        client: project.client,
        dimensions: image.image.file.details.image,
        landscape:
          image.image.file.details.image.width >
          image.image.file.details.image.height,
      })
    )
  )

  return flattended
}
