import React, { useContext } from "react"
import { Context } from "../context"
import styled, { css } from "styled-components"

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  bottom: 1rem;
  width: 100%;
  height: 25%;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;

  ${({ mobileProjectsActive }) =>
    mobileProjectsActive &&
    css`
      opacity: 0;
    `}

  svg {
    width: auto;
    height: 100%;
  }
`

const SVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.4 734.4">
    <path
      fill="#000000"
      d="M0 700.7l32.7 33.7 32.7-33.7-2.8-2.5-28 28.8V0h-3.8v727L2.7 698.2z"
    />
  </svg>
)

const Arrow = () => {
  const { handleMobileArrowClick, mobileProjectsActive } = useContext(Context)
  return (
    <Container
      mobileProjectsActive={mobileProjectsActive}
      onClick={() => handleMobileArrowClick()}
    >
      <SVG />
    </Container>
  )
}

export default Arrow
