import React, { useContext } from "react"
import styled, { css } from "styled-components"
import { Context } from "../context"

const StyledTitle = styled.div`
  position: absolute;
  width: 28.5rem;
  background-color: ${({ theme }) => theme.white};
  background: ${({ background }) => background};
  color: ${({ fontColor }) => fontColor};
  z-index: 100000;

  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      visibility: hidden;
    `}

  @media (min-width: ${({ theme }) => theme.desktop}) {
    width: 41.5rem;
    line-height: 0.96;
  }

  @media (max-width: ${({ theme }) => theme.desktop}) {
    width: 38.5rem;
    line-height: 0.98;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: inherit;
    top: 0;
    padding: 1rem;
    z-index: 20;
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
  }

  ${({ mobileProjectsVisible }) =>
    mobileProjectsVisible &&
    css`
      @media (max-width: ${({ theme }) => theme.mobile}) {
        opacity: 1;
      }
    `}

  /* HIDE ON DEVICES */
  ${({ hide }) =>
    hide === "mobile" &&
    css`
      @media (max-width: ${({ theme }) => theme.mobile}) {
        display: none !important;
      }
    `}

    ${({ hide }) =>
      hide === "desktop" &&
      css`
        @media (min-width: ${({ theme }) => theme.mobile}) {
          display: none !important;
        }
      `}
`

const ProjectTitle = ({ visible, hide }) => {
  const { projects, active, mobileProjectsVisible } = useContext(Context)
  return projects.length > 0 ? (
    <StyledTitle
      visible={visible}
      hide={hide}
      background={projects[active].backgroundColor}
      mobileProjectsVisible={mobileProjectsVisible}
      fontColor={projects.length > 0 ? projects[active].fontColor : "#000000"}
    >
      {projects[active].i} {projects[active].client}
    </StyledTitle>
  ) : null
}

export default ProjectTitle
