import React, { useContext } from "react"
import { Context } from "../context"
import Project from "./project"
import ProjectTitle from "./title"
import styled, { css } from "styled-components"

const Container = styled.div`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    transform: translateY(100%);
    transition: ${({ theme }) => theme.easing};
    /* opacity: 0;
    transition: opacity 0.6s ease-in-out;

    ${({ pushUp }) =>
      pushUp &&
      css`
        opacity: 1;
      `} */
  }

  ${({ pushUp }) =>
    pushUp &&
    css`
      @media (max-width: ${({ theme }) => theme.mobile}) {
        transform: translateY(0);
      }
    `}
`

const Close = styled.div`
  display: none;
  position: absolute;
  bottom: 1rem;
  width: 100%;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
  z-index: 10000;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: block;
  }
`

const Projects = () => {
  const {
    projects,
    active,
    mobileProjectsActive,
    handleMobileArrowClick,
  } = useContext(Context)
  return (
    <Container pushUp={mobileProjectsActive}>
      <ProjectTitle visible={true} hide={"desktop"} />
      {projects.map((project, i) => (
        <Project
          key={project.i}
          first={i === 0}
          project={project}
          active={active === i}
        />
      ))}

      <Close
        onClick={() => handleMobileArrowClick(true)}
        fontColor={projects.length > 0 ? projects[active].fontColor : "#000000"}
      >
        (Close)
      </Close>
    </Container>
  )
}

export default Projects
